import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Helmet from 'react-helmet';
import { localizedPath } from 'gatsby-theme-i18n/src/helpers';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import styled from 'styled-components';

import { bubbleSvg } from '../helpers';
import { lightTeal } from '../colors';
import '../globalStyles.css';
import Layout from '../components/Layout';

const SubpageWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
`;

const SubpageContainer = styled.div`
  margin: auto;
`;

const MainSection = styled.div`

`;
const TealBackground = styled.div`
  background-color: var(--color-teal);
`;
const SubpageHeader = styled.div`
  max-width: 1920px;
  margin: auto;
  display: flex;
  padding: 50px 100px 100px 100px;

  @media (max-width: 1350px) {
    padding: 50px;
  }

  @media (max-width: 1023px)  {
    flex-direction: column;
    padding: 50px 25px;
  }

  @media (max-width: 550px) {
    padding: 25px 0 50px 0;
  }
`;

const TextSectionHeader = styled.div`
  width: 50%;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
    margin: auto;
  }
`;

const Headline = styled.h1`
  font-size: 54px;
  white-space: pre-wrap;
  margin-bottom: 6%;
  margin-top: 8%;

  & p {
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (max-width: 1600px) {
    font-size: 44px;
  }

  @media (max-width: 1250px) {
    font-size: 36px;
  }

  @media (max-width: 1023px) {
    margin: auto;
    margin-top: 0;
    width: 80%;
    padding-bottom: 3%;
  }

  @media (max-width: 900px) {
    font-size: 38px;
    width: 90%;
  }

  @media (max-width: 750px) {
    font-size: 37px;
    padding-bottom: 2%;
    width: 95%;
  }

  @media (max-width: 700px) {
    font-size: 28px;
    padding-bottom: 4%;
  }

  @media (max-width: 550px) {
    width: 95%;
    margin: auto;
    padding-bottom: 5%;
  }

  @media (max-width: 450px) {
    font-size: 23px;
  }
`;

const Subtext = styled.div`
  white-space: pre-wrap;
  font-size: 20px;
  margin-top: 0;
  width: 90%;
  margin-bottom: 8%;
  line-height: 1.25;

  & p {
    margin-top: 0;
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  @media (max-width: 1600px) {
    font-size: 18px;
    width: 100%;
  }

  @media (max-width: 1250px) {
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    font-size: 18px;
    width: 85%;
    margin: auto;
    margin-bottom: 5%;
  }

  @media (max-width: 900px) {
    width: 90%;
    font-size: 17px;
  }

  @media (max-width: 750px) {
    width: 95%;
  }

  @media (max-width: 700px) {
    font-size: 16px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    margin: auto;
    white-space: normal;
    margin-bottom: 8%;
  }

`;

const ImageWrapperHeader = styled.div`
  width: 36%;
  padding-top: 40.57%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  margin: auto;

  @media (max-width: 1023px) {
    width: 60%;
    padding-top: 60.86%;
    margin-top: 5%;
  }

  @media (max-width: 900px) {
    width: 75%;
    padding-top: 76.07%;
  }

  @media (max-width: 700px) {
    width: 90%;
    padding-top: 91.28%;
  }

`;

const BackgroundBubble = styled.div`
  background-image: ${bubbleSvg(lightTeal, 45)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 110%;
  height: 110%;
  left: 19%;
  right: 0;
  top: -5%;
  bottom: 0;

  @media (max-width: 1250px) {
    width: 130%;
  }

  @media (max-width: 1023px) {
    left: -5%;
    top: -12%;
    width: 110%;
    height: 110%;
  }

`;

const ScreenContainerHeader = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  height: 100%;
  width: 90.5%;
  max-width: 704.33px;
  position: absolute;
  left: 12.2%;
  right: 0;
  top: 0.3%;
  bottom: 0;

  @media (max-width: 1023px) {
    left: 5.2%;
    top: -8%;
  }
`;

const ImageContainerHeader = styled.div`
  background-image: url(/img/mockup_tablet.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 7%;
  right: 0;
  top: 0;
  bottom: 0;

  @media (min-width: 1921px) {
    max-width: 774px;
  }

  @media (max-width: 1023px) {
    left: 0;
    top: -8.2%;
  }
`;

// const VideoContainer = styled.div`
//   max-width: 1920px;
//   margin: auto;
//   display: flex;
//   flex-direction: column;
// `;

// const VideoHeadline = styled.h2`
//   margin: auto;
//   text-align: center;
//   font-weight: 900;
//   font-size: 32px;
//   padding-top: 5%;
//   max-width: 95%;

//   & p {
//     margin-bottom: 0;
//     margin-top: 0;
//   }

//   @media (max-width: 1250px) {
//     font-size: 28px;
//   }

//   @media (max-width: 1023px) {
//     font-size: 24px;
//   }

//   @media (max-width: 900px) {
//     font-size: 20px;
//   }

//   @media (max-width: 700px) {
//     font-size: 18px;
//     padding-top: 8%;
//   }
// `;

// const VideoSection = styled.div`
//   display: flex;
//   justify-content: center;
//   height: 31.7vw;
//   padding-top: 3%;

//   @media (min-width: 1921px) {
//     width: 1122px;
//     height: 631.125px;
//     margin: auto;
//   }

//   @media (max-width: 900px) {
//     height: 50.6vw;
//   }

//   @media (max-width: 550px) {
//     height: 53.5vw;
//   }

//   & iframe {
//     width: 56.25%;
//     height: 100%;
//     border: none;

//     @media (min-width: 1921px) {
//       width: 1122px;
//       height: 631.125px;
//     }

//     @media (max-width: 900px) {
//       width: 90%;
//     }

//     @media (max-width: 550px) {
//       width: 95%;
//     }
//   }
// `;

const TrustFactContainer = styled.div`
  max-width: 1920px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  font-size: 18px;
  padding-top: 5%;

  @media (max-width: 1250px) {
    font-size: 17px;
    max-width: 95%;
  }

  @media (max-width: 1023px) {
    font-size: 16px;
  }

  @media (max-width: 900px) {
    font-size: 15px;
    padding-bottom: 5%;
    padding-top: 8%;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`;

const TrustFactSection = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  padding-bottom: 5%;

  @media (max-width: 550px) {
    width: 100%;
    padding-bottom: 8%;
  }
`;

const TrustFactIcon = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60px;

  @media (max-width: 900px) {
    height: 50px;
  }

  @media (max-width: 400px) {
    height: 40px;
  }
`;

const TrustFactHeadline = styled.p`
  font-weight: 700;
`;

const TrustFactText = styled.p`
  max-width: 60%;
  margin: auto;
  margin-top: 0;

  @media (max-width: 900px) {
    max-width: 80%;
  }

  @media (max-width: 400px) {
    max-width: 95%;
  }
`;

const OverviewContainer = styled.div`
  max-width: 1920px;
  display: flex;
  justify-content: space-between;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: auto;
  margin-bottom: 4%;
  margin-top: 4%;

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }

  @media (max-width: 550px) {
    width: 95%;
    margin: auto;
  }

  & div {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 800px) {
      flex: 50%;
    }
  }
`;

const OverviewSection = styled.div`

  & p {
    color: var(--color-white);
    font-weight: 700;

    @media (max-width: 1023px) {
      font-size: 14px;
      padding-left: 8px;
      padding-right: 8px;

    }

    @media (max-width: 800px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

const Icon = styled.img`
  width: 30%;
  max-width: 130px;
  cursor: pointer;

  @media (max-width: 1023px) {
    width: 90px;
  }

  @media (max-width: 600px) {
    width: 75px;
  }
`;

const InfoContainer = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 80px 100px 0 100px;

  @media (max-width: 1350px) {
    padding: 70px 50px 0 50px;
  }

  @media (max-width: 1023px) {
    padding: 50px 25px 0 25px;
  }

  @media (max-width: 550px) {
    padding: 50px 0 0 0;
  }
`;

const InfoSection1 = styled.div`
  color: var(--color-dark-grey);
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;

  & img {
    width: 45%;
    height: 100%;
    margin: auto;
    margin-right: 0;

    @media (max-width: 1150px) {
      width: 49%;
    }

    @media (max-width: 1023px) {
      width: 70%;
      margin: auto;
      margin-top: 5%;
    }
  
    @media (max-width: 700px) {
      width: 90%;
    }
  
    @media (max-width: 550px) {
      width: 95%;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (max-width: 550px) {
    padding-bottom: 50px;
  }
`;

const InfoTextContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const InfoHeadline = styled.h2`
  font-weight: 900;
  font-size: 32px;
  color: var(--color-dark-grey);
  margin-bottom: 3%;
  margin-top: 0;

  @media (max-width: 1250px) {
    font-size: 24px;
  }

  @media (max-width: 1023px) {
    font-size: 22px;
  }

  @media (max-width: 900px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 16px;
    width: 95%;
    margin: auto;
  }
`;

const InfoText = styled.div`
  white-space: pre-wrap;
  font-size: 18px;
  line-height: 1.25;

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  & ul {
    @media (max-width: 450px) {
      padding-left: 20px;
    }
  }

  @media (max-width: 1250px) {
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    font-size: 16px;
  }

  @media (max-width: 900px) {
    font-size: 15px;
  }

  @media (max-width: 550px) {
    font-size: 14px;
    white-space: normal;
    width: 95%;
    margin: auto;
  }

`;

const InfoSection2 = styled(InfoSection1)`
  flex-direction: row-reverse;

  & img {
    margin: auto;
    margin-left: 0;

    @media (max-width: 1023px) {
      width: 70%;
      margin: auto;
      margin-top: 5%;
    }

    @media (max-width: 700px) {
      width: 90%;
    }
  
    @media (max-width: 550px) {
      width: 95%;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const InfoSection3 = styled(InfoSection1)`

`;

const InfoSection4 = styled(InfoSection2)`

`;

const SlideContainer = styled.div`
  width: 50%;
  align-content: center;

  & button {
    padding: 7px 11px;
  }
  
  & ul {
    padding-inline-start: 0;
  }

  & .react-slideshow-container+ul.indicators {
    margin-top: 10px;
    margin-bottom: 0;
  }

  & .react-slideshow-container+ul.indicators li {
    width: 33px;
    padding: 0;
  }

  & .react-slideshow-container + ul.indicators .each-slideshow-indicator::before {
    background: var(--color-teal);
    width: 9px;
    height: 7px;
    position: relative;
  }

  @media (max-width: 1023px) {
    padding-top: 8%;
    width: 100%;
  }

`;

const Image = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  width: 35vw;
  height: calc(35vw / 16 * 9);
  max-width: 750px;
  max-height: calc(750px / 16 * 9);


  @media (max-width: 1250px) {
    width: 40vw;
    height: calc(40vw / 16 * 9);  
  }

  @media (max-width: 1023px ) {
    width: 65vw;
    height: calc(65vw / 16 * 9);
  }

  @media (max-width: 750px) {
    width: 75vw;
    height: calc(75vw / 16 * 9);
  }

  @media (max-width: 550px) {
    width: 95vw;
    height: calc(95vw / 16 * 9);
  }

`;

const ContactSection = styled.div`
  max-width: 1920px;
  margin: auto;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px 100px;

  @media (max-width: 1350px) {
    padding: 50px;
  }

  @media (max-width: 550px) {
    padding: 50px 0 ;
  }
`;

const ContactHeadline = styled.p`
  font-size: 32px;
  font-weight: 900;
  white-space: pre-wrap;
  margin-top: 0;
  margin-bottom: 3%;

  @media (max-width: 1250px) {
    font-size: 26px;
  }

  @media (max-width: 1023px) {
    font-size: 24px;
  }

  @media (max-width: 900px) {
    font-size: 22px;
  }

  @media (max-width: 700px) {
    font-size: 20px;
    white-space: normal;
  }

  @media (max-width: 550px) {
    font-size: 18px;
    width: 95%;
    margin: auto;
    padding-bottom: 3%;
    padding-top: 2.5%;
  }
`;

const ContactSubtext = styled.p`
  white-space: pre-wrap;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 3%;

  & p {
    margin-top: 0;
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  @media (max-width: 1600px) {
    font-size: 18px;
    white-space: normal;
  }

  @media (max-width: 1250px) {
    font-size: 17px;
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 16px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    width: 95%;
    margin: auto;
    margin-bottom: 3%;
    white-space: normal;
  }
`;

const ContactButton = styled.button`
  margin: auto;
  color: var(--color-white);
  background-color: var(--color-magenta);
  font-size: 24px;
  padding: 15px 30px;
  transition: none;

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }

  &:hover {
    background-size: 0;
  }

  @media (max-width: 1600px) {
    font-size: 19px;
  }

  @media (max-width: 1250px) {
    font-weight: 900;
    font-size: 17px;
    padding: 15px;
  }

  @media (max-width: 1050px) {
    font-weight: 700;
  }

  @media (max-width: 1023px) {
    font-weight: 900;
    font-size: 20px;
  }

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 700px) {
    font-size: 17px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    margin: 0 2.5%;
  }

  @media (max-width: 450px) {
    margin: 0 15px;
  }
`;

const InfoTextContactButton = styled.p`
  margin: 5px;
  font-size: 14px;

  @media (max-width: 1600px) {
    font-size: 12px;
  }

  @media (max-width: 700px) {
    font-size: 11px;
  }

  @media (max-width: 450px) {
    margin: 5px 15px;
  }
`;

const FAQContainer = styled.div`
  padding: 100px;
  max-width: 1920px;
  margin: auto;

  @media (max-width: 1350px) {
    padding: 100px 50px;
  }

  @media (max-width: 1023px) {
    padding: 100px 25px;
  }

  @media (max-width: 550px) {
    padding: 50px 2.5% 75px 2.5%;
  }
`;

const FAQHeadline = styled.h2`
  margin: auto;
  font-weight: 900;
  font-size: 32px;
  color: var(--color-dark-grey);
  margin-bottom: 3%;
  margin-top: 0;

  @media (max-width: 1250px) {
    font-size: 24px;
  }

  @media (max-width: 1023px) {
    font-size: 22px;
  }

  @media (max-width: 900px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 16px;
  }
`;

const FAQSection = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-dark-grey);
  justify-content: space-between;
  border-bottom: 1px solid;

  & label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    cursor: pointer;
    transition: color 350ms ease;

    & h2 {
      font-weight: 400;
      font-size: 24px;

      @media (max-width: 1250px) {
        font-size: 20px;
      }
    
      @media (max-width: 1023px) {
        font-size: 18px;
      }
    
      @media (max-width: 900px) {
        font-size: 16px;
      }
    
      @media (max-width: 700px) {
        font-size: 14px;
      }
    }

    & svg {
      width: 30px;
      margin: 5px 5px 5px 20px;
      flex-shrink: 0;
      transition: transform 350ms ease;

      @media (max-width: 900px) {
        width: 20px;
      }
    }
  }

  &.tab input:checked ~ .tab__label svg {
    transform: rotate(180deg);
  }

  &.tab input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  & .tab__content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.35s;
    padding-left: 3%;
    font-size: 22px;
    width: 80%;
    line-height: 1.25;

    & p {
      @media (max-width: 700px) {
        margin-top: 0;
      }  
    }

    @media (max-width: 1250px) {
      font-size: 20px;
    }
  
    @media (max-width: 1023px) {
      font-size: 18px;
    }
  
    @media (max-width: 900px) {
      font-size: 16px;
    }
  
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }

  &.tab input:checked ~ .tab__content {
    max-height: 100vh;
    color: var(--color-blue);
  }
`;

export default class ProductSubpages extends Component {
  static propTypes = {
    pageContext: PropTypes.object,
    data: PropTypes.object,
  };

  // eslint-disable-next-line class-methods-use-this
  renderContent(page) {
    const id = page?.productSubpage?.id || Math.random();
    const submenu = page?.submenus?.edges?.find((menu) => menu?.node?.frontmatter?.submenuId === page?.productSubpageSubpage?.frontmatter?.submenuId)?.node;

    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
    };

    return (
      <SubpageContainer key={id}>
        <MainSection>
          <TealBackground>
            <SubpageHeader>
              <TextSectionHeader>
                <Headline>
                  <MDXRenderer>{page?.productSubpage?.frontmatter?.subpageHeadline}</MDXRenderer>
                </Headline>
                <Subtext>
                  <MDXRenderer>{page?.productSubpage?.frontmatter?.productSubpageSubtext}</MDXRenderer>
                </Subtext>
              </TextSectionHeader>
              <ImageWrapperHeader>
                <BackgroundBubble />
                <ScreenContainerHeader url={page?.productSubpage?.frontmatter?.subpageHeaderImage} />
                <ImageContainerHeader />
              </ImageWrapperHeader>
            </SubpageHeader>
          </TealBackground>
          {/* {page?.productSubpage?.frontmatter?.subpageVideo && (
            <VideoContainer>
              <VideoHeadline>
                <MDXRenderer>{page?.productSubpage?.frontmatter?.videoHeadline}</MDXRenderer>
              </VideoHeadline>
              <VideoSection>
                <iframe
                  src={page?.productSubpage?.frontmatter?.subpageVideo}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </VideoSection>
            </VideoContainer>
          )} */}
          <TrustFactContainer>
            {page?.productSubpage?.frontmatter?.trustFactsProductSubpage.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TrustFactSection key={index}>
                <TrustFactIcon url={item?.trustFactIcon} />
                <TrustFactHeadline>{item?.trustFactHeadline}</TrustFactHeadline>
                <TrustFactText>{item?.trustFactText}</TrustFactText>
              </TrustFactSection>
            ))}
          </TrustFactContainer>
          <TealBackground>
            <OverviewContainer>
              {page?.productSubpage?.frontmatter?.productSubpagesOverview?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <OverviewSection key={index}>
                  <Icon
                    src={item?.overviewIcon}
                    alt={item?.altAttributeOverviewIcon}
                    onClick={() => {
                      navigate(localizedPath({
                        locale: `${page?.productSubpage?.fields?.locale}`,
                        path: `/${item?.iconLink}/`,
                        defaultLang: 'de',
                        prefixDefault: false,
                      }));
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        navigate(localizedPath({
                          locale: `${page?.productSubpage?.fields?.locale}`,
                          path: `/${item?.iconLink}/`,
                          defaultLang: 'de',
                          prefixDefault: false,
                        }));
                      }
                    }}
                  />
                  <p>{item?.overviewText}</p>
                </OverviewSection>
              ))}
            </OverviewContainer>
          </TealBackground>
          <InfoContainer>
            <InfoSection1>
              <InfoTextContainer>
                <InfoHeadline>{page?.productSubpage?.frontmatter?.textSectionHeadline1}</InfoHeadline>
                <InfoText>
                  <MDXRenderer>{page?.productSubpage?.frontmatter?.textSectionText1}</MDXRenderer>
                </InfoText>
              </InfoTextContainer>
              <SlideContainer>
                <Slide {...properties}>
                  {page?.productSubpage?.frontmatter?.sliderImages1?.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Image key={index} url={item.productSubpageSliderImage1} />
                  ))}
                </Slide>
              </SlideContainer>
            </InfoSection1>
            { page?.productSubpage?.frontmatter?.textSectionHeadline2 && (
              <InfoSection2>
                <InfoTextContainer>
                  <InfoHeadline>{page?.productSubpage?.frontmatter?.textSectionHeadline2}</InfoHeadline>
                  <InfoText>
                    <MDXRenderer>{page?.productSubpage?.frontmatter?.textSectionText2}</MDXRenderer>
                  </InfoText>
                </InfoTextContainer>
                <SlideContainer>
                  <Slide {...properties}>
                    {page?.productSubpage?.frontmatter?.sliderImages2?.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Image key={index} url={item.productSubpageSliderImage2} />
                    ))}
                  </Slide>
                </SlideContainer>
              </InfoSection2>
            )}
            { page?.productSubpage?.frontmatter?.textSectionHeadline3 && (
              <InfoSection3>
                <InfoTextContainer>
                  <InfoHeadline>{page?.productSubpage?.frontmatter?.textSectionHeadline3}</InfoHeadline>
                  <InfoText>
                    <MDXRenderer>{page?.productSubpage?.frontmatter?.textSectionText3}</MDXRenderer>
                  </InfoText>
                </InfoTextContainer>
                <SlideContainer>
                  <Slide {...properties}>
                    {page?.productSubpage?.frontmatter?.sliderImages3?.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Image key={index} url={item.productSubpageSliderImage3} />
                    ))}
                  </Slide>
                </SlideContainer>
              </InfoSection3>
            )}
            { page?.productSubpage?.frontmatter?.textSectionHeadline4 && (
              <InfoSection4>
                <InfoTextContainer>
                  <InfoHeadline>{page?.productSubpage?.frontmatter?.textSectionHeadline4}</InfoHeadline>
                  <InfoText>
                    <MDXRenderer>{page?.productSubpage?.frontmatter?.textSectionText4}</MDXRenderer>
                  </InfoText>
                </InfoTextContainer>
                <SlideContainer>
                  <Slide {...properties}>
                    {page?.productSubpage?.frontmatter?.sliderImages4?.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Image key={index} url={item.productSubpageSliderImage4} />
                    ))}
                  </Slide>
                </SlideContainer>
              </InfoSection4>
            )}
          </InfoContainer>
          <TealBackground>
            <ContactSection>
              <ContactHeadline>{page?.productSubpage?.frontmatter?.contactHeadline}</ContactHeadline>
              <ContactSubtext>{page?.productSubpage?.frontmatter?.contactSubtext}</ContactSubtext>
              <ContactButton
                type="button"
                onClick={() => {
                  navigate(localizedPath({
                    locale: `${page?.productSubpage?.fields?.locale}`,
                    path: '/contact',
                    defaultLang: 'de',
                    prefixDefault: false,
                  }));
                }}
              >
                { page?.productSubpage?.frontmatter?.contactbuttonText || 'BUTTONTEXT NOT FOUND' }
              </ContactButton>
              <InfoTextContactButton>{page?.productSubpage?.frontmatter?.contactInfoTextButton}</InfoTextContactButton>
            </ContactSection>
          </TealBackground>
          <FAQContainer>
            <FAQHeadline>{page?.productSubpage?.frontmatter?.FAQsHeadline}</FAQHeadline>
            {page?.productSubpage?.frontmatter?.FAQs.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <FAQSection key={index} className="tab">
                <input type="checkbox" name="accordion" id={`accordeon-${index}`} />
                <label htmlFor={`accordeon-${index}`} className="tab__label">
                  <h2>{item?.question}</h2>
                  <svg width="46" height="26" viewBox="0 0 46 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {/* eslint-disable-next-line max-len, max-len */}
                    <path d="M20.8787 25.1213C22.0503 26.2929 23.9497 26.2929 25.1213 25.1213L44.2132 6.02944C45.3848 4.85787 45.3848 2.95837 44.2132 1.7868C43.0416 0.615224 41.1421 0.615224 39.9706 1.7868L23 18.7574L6.02944 1.7868C4.85787 0.615224 2.95837 0.615224 1.7868 1.7868C0.615224 2.95837 0.615224 4.85787 1.7868 6.02944L20.8787 25.1213ZM20 22V23H26V22H20Z" fill="#3A3A3A" />
                  </svg>
                </label>
                <div className="tab__content">
                  <p>{item?.answer}</p>
                </div>
              </FAQSection>
            ))}
          </FAQContainer>
        </MainSection>
      </SubpageContainer>
    );
  }

  render() {
    const { pageContext, data } = this.props;
    const page = data || {};

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return (
      <Layout currentPath={pageContext.originalPath} submenuId={page?.productSubpage?.frontmatter?.submenuId}>
        <Helmet>
          <title>{`${data?.productSubpage?.frontmatter?.titleMetaTag} | presono`}</title>
          <meta
            name="description"
            content={data?.productSubpage?.frontmatter?.snippetTextSubpage}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.productSubpage?.frontmatter?.titleMetaTag} />
          <meta property="og:description" content={data?.productSubpage?.frontmatter?.snippetTextSubpage} />
          <meta property="og:image" content={`https://presono.com${data?.productSubpage?.frontmatter?.subpageHeaderImage}`} />
          <meta name="twitter:title" content={data?.productSubpage?.frontmatter?.titleMetaTag} />
          <meta name="twitter:description" content={data?.productSubpage?.frontmatter?.snippetTextSubpage} />
          <meta name="twitter:image" content={`https://presono.com${data?.productSubpage?.frontmatter?.subpageHeaderImage}`} />
        </Helmet>
        <SubpageWrapper className="off-white-background">
          { this.renderContent(page) }
        </SubpageWrapper>
      </Layout>
    );
  }
}

export const query = graphql`
query SubpageQuery($title: String!, $locale: String!) {
  productSubpage: mdx(
    fields: {locale: {eq: $locale}}
    frontmatter: {title: {eq: $title}, layout: {eq: "productSubpage"}}
  ) {
    frontmatter {
      title
      titleMetaTag
      snippetTextSubpage
      submenuId
      subpageId
      submenuSubheadline
      subpageHeadline
      productSubpageSubtext
      subpageHeaderImage
      videoHeadline
      subpageVideo
      trustFactsProductSubpage {
        trustFactIcon
        trustFactHeadline
        trustFactText
      }
      productSubpagesOverview {
        overviewIcon
        altAttributeOverviewIcon
        iconLink
        overviewText
      }
      textSectionHeadline1
      textSectionText1
      sliderImages1 {
        productSubpageSliderImage1
      }
      textSectionHeadline2
      textSectionText2
      sliderImages2 {
        productSubpageSliderImage2
      }
      textSectionHeadline3
      textSectionText3
      sliderImages3 {
        productSubpageSliderImage3
      }
      textSectionHeadline4
      textSectionText4
      sliderImages4 {
        productSubpageSliderImage4
      }
      contactHeadline
      contactSubtext
      contactbuttonText
      contactInfoTextButton
      FAQsHeadline
      FAQs {
        question
        answer
      }
    }
    id
    slug
    fields {
      locale
    }
  }
  submenus: allMdx(
    sort: { order: ASC, fields: [frontmatter___submenuId] }
    filter: {
      frontmatter: { layout: {eq: "submenu"} }
      fields: {locale: {eq: $locale}}
    }
  ) {
    edges {
      node {
        frontmatter {
          submenuId
          title
        }
        fields {
          locale
        }
      }
    }
  }
  productSubpages: allMdx(
    sort: { order: ASC, fields: [frontmatter___submenuId, frontmatter___subpageId] }
    filter: {
      frontmatter: { layout: {eq: "productSubpage"} }
      fields: {locale: {eq: $locale}}
    }
  ) {
    edges {
      node {
        frontmatter {
          title
          submenuId
          subpageId
          submenuSubheadline
        }
        slug
        id
        fields {
          locale
        }
      }
    }
  }
}
`;
